import * as demoProductComponentsSelectReactSingleSelectTsx from '@content/product/components/select/react/single-select.tsx';
import * as demoProductComponentsSelectReactSingleSelectSearchTsx from '@content/product/components/select/react/single-select-search.tsx';
import * as demoProductComponentsSelectReactMultiSelectTsx from '@content/product/components/select/react/multi-select.tsx';
import * as demoProductComponentsSelectReactStateDisabledTsx from '@content/product/components/select/react/state-disabled.tsx';
import * as demoProductComponentsSelectReactMultiSelectSearchTsx from '@content/product/components/select/react/multi-select-search.tsx';
import * as demoProductComponentsSelectReactStateValidTsx from '@content/product/components/select/react/state-valid.tsx';
import * as demoProductComponentsSelectReactStateInvalidTsx from '@content/product/components/select/react/state-invalid.tsx';
import * as demoProductComponentsSelectReactSelectChipTsx from '@content/product/components/select/react/select-chip.tsx';
import * as demoProductComponentsSelectReactMultiSelectChipTsx from '@content/product/components/select/react/multi-select-chip.tsx';
import * as React from 'react'

export default { demoProductComponentsSelectReactSingleSelectTsx, demoProductComponentsSelectReactSingleSelectSearchTsx, demoProductComponentsSelectReactMultiSelectTsx, demoProductComponentsSelectReactStateDisabledTsx, demoProductComponentsSelectReactMultiSelectSearchTsx, demoProductComponentsSelectReactStateValidTsx, demoProductComponentsSelectReactStateInvalidTsx, demoProductComponentsSelectReactSelectChipTsx, demoProductComponentsSelectReactMultiSelectChipTsx, React }